import { createBrowserHistory, History } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { datadogRum } from '@datadog/browser-rum';

import { App } from './app/App';
import './index.css';

if (!HTMLElement.prototype.scrollTo) {
  HTMLElement.prototype.scrollTo = function (options: any) {
    this.scrollTop = options?.top || 0;
    this.scrollLeft = options?.left || 0;
  };
}

datadogRum.init({
  applicationId: 'd2c342e2-07ef-4185-9446-bb3ade29c721',
  clientToken: 'pub31c376da00d50fcd551c38c2d2bded3a',
  site: 'datadoghq.com',
  service: 'racpad-manualpo1',
  env: process.env.ENVMNT,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100, // if not included, the default is 100
  trackResources: true,
  trackLongTasks: true,
});

window.renderManualPO = (containerId: string, history: History) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.render(<App history={history} />, elem);
  }
};

window.unmountManualPO = (containerId: string) => {
  const elem = document.getElementById(containerId);

  if (elem) {
    ReactDOM.unmountComponentAtNode(elem);
  }
};

if (!window.isRenderedByContainer) {
  import('./bootstrap').then(() => {
    const defaultHistory = createBrowserHistory({
      basename: process.env.PUBLIC_URL,
    });

    window.renderManualPO('root', defaultHistory);
  });
}
